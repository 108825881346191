import { mapActions, mapGetters, mapState } from 'vuex'
import Wallet from '@/components/base/Wallet'
import { resStatus } from '@/utils/resUtils'
import Dialog from '@/components/dialog'
import { copy } from '@/utils/copy'
// import QrcodeVue from 'qrcode.vue'
import { deviceCheck } from '@/utils/device'
import { clickEventInterval } from '@/utils/event'
import { Decimal } from 'decimal.js'
import { amountRoundDown } from '@/utils/money'
import { error } from 'logrocket'

export default {
  components: {
    Wallet,
    Dialog,
    //  QrcodeVue
  },

  data: () => ({
    selectedUnit: 'USDT', // 目前幣種單位有USDT和站台法幣兩種值(前端寫死)
    valid: true, // 輸入金額 表單驗証

    userPaymentList: [], // 一般 支付列表
    paymenetListAreaHidden: false, // 支付列表 顯示更多狀態
    bankCard: [], // 銀行卡 支付列表
    usdtList: [], // usdt 支付列表

    // 管道資料 pipeline
    pipeline: {
      // 顯示
      status: false,

      // 第一層
      lv1: {
        // 標題
        title: ['deposit.paymentChannel'],
      },

      lv2: {
        // 標題
        title: ['deposit.paymentChannel'],
      },
    },

    // 存款表單
    form: {
      paytype: '', // 支付類型代碼，有"BANK"私卡、其他類型則要去打/trans/payment-list取得
      amount: '', // 提款金額
      paytype_no: null, // 支付 多通道
      payment_platform: '', // 虛擬幣存款需提供平台
      protocol: '', // 虛擬幣存款需提供協議
    },

    // 存款金額 範圍
    amount: {
      max_amount: 0,
      min_amount: 0,
    },

    wallet: {
      main: 0,
      locked: 0,
    },

    // 預設快捷操作金額 (range)
    fastAmountBtn: [100, 500, 1000],
    // 快捷操作金額 (option)
    fastAmountBtnForOption: [],

    dialog: {
      status: false,
      type: '',
      content: [],
    },

    // 目前點擊的支付金流型態 (range / option)
    activeUsdtIndex: null, // 點擊中的usdt item
    activePaymentType: null,
    activePayType: null, // 點擊中的付款方式
    activeCurrency: null, // 目前的幣別 (非幣別符號)
    activePipelineItem: null, // 點擊中的通道
    activeCurrencySymbol: null, // 點擊中通道的幣別符號
    pipelineList: [], // 渠道(通道)列表
    activePipeline: null, // 當前選擇的 渠道 (channel)
    activePaymentPlatform: null, // 支付平台
    activeProtocolIndex: null, // 當前選擇的協議
    device: null,

    // 執行過後，是否可以再打 api
    eventLaunch: {
      deposit: null,
    },

    usdtListShow: false, // 是否顯示USDT協議區塊
    protocolClickStatus: false, // 協議按鈕點擊狀態
    completed_baseinfo: '', // 完善資料是否填寫完成
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'currencySymbol', 'currencySymbol_']),
    ...mapState({
      theme: state => state.theme,
      settings: state => state.websiteData.settings,
      showPaymentPipeline: state => state.websiteData.func_switch.payments_group_display,
      isCompletedBaseinfoBeforeDeposit: state => state.websiteData.func_switch.is_completed_baseinfo_before_deposit// 取得網站基本資料
      ,
    }),
    usdtRate() {
      return this.$store.state.websiteData.usdt_exchange_rate_deposit
    },
    estimatedUSDT() {
      // 當幣種單位選擇法幣時,將預計支付換算成USDT
      return this.convertCurrency('USDT')
    },
    estimatedCUR() {
      // 當幣種單位選擇USDT時,將預計支付換算成法幣金額
      return this.convertCurrency('CUR')
    },
    minMaxRangeText() {
      // 單筆存款範圍
      const { min, max } = this.getMinMaxData(this.amount.min_amount, this.amount.max_amount)
      const isUSDT = this.activePayType === 'USDT' && this.selectedUnit === 'USDT'
      const curSymbol = isUSDT ? '' : this.activeCurrencySymbol // 法幣符號
      const uSymbol = isUSDT ? 'U' : '' // Ｕ符號

      return `${curSymbol}${min}${uSymbol}~${curSymbol}${max}${uSymbol}`
    },
    // 是否顯示輸入金額區
    showAmountArea() {
      return !!(
        (this.form.paytype &&
          this.pipelineList.length > 0 &&
          this.form.paytype_no) ||
        this.form.paytype === 'BANK' ||
        (this.activePayType === 'USDT' && this.protocolClickStatus) ||
        (this.form.paytype && this.pipelineList.length === 0 && this.activePayType !== 'USDT')
      )
    },

    /**
     * 付款方式列表數量，是否超過 7 個項目
     * 開啟顯示更多箭頭
     * @date 2021-06-15
     */
    paymentListLength() {
      return this.userPaymentList.length > 7
    },

    addressLengthFormat() {
      if (this.dialog.content.transfer_address) {
        return this.dialog.content.transfer_address.length < 128
          ? this.dialog.content.transfer_address
          : this.dialog.content.transfer_address.substr(0, 128) + '...'
      }
      return 0
    },

    rules() {
      return {
        amount: {
          required: value => !!value || this.$t('form.missAmount'),
          // 超過中心錢包
          overBlance: val =>
            val <= Number(this.wallet.main) ||
            this.$t('form.errorAmountNoMoney'),
          numChk: value => /^\d+(\.\d{1,2})?$/.test(value) || this.$t('deposit.num_chk'), // 僅接受數字和小數點,且小數點後面最多兩位
          min: value => {
            if (!value || !/^\d+(\.\d{1,2})?$/.test(value)) return true
            const { min, max } = this.getMinMaxData(this.amount.min_amount, this.amount.max_amount)
            return new Decimal(value).greaterThanOrEqualTo(min) ||
              this.$t('form.errorAmountOutofRange', {
                range: this.minMaxRangeText,
              })
          },

          max: value => {
            if (!value || !/^\d+(\.\d{1,2})?$/.test(value)) return true
            const { min, max } = this.getMinMaxData(this.amount.min_amount, this.amount.max_amount)
            return new Decimal(value).lessThanOrEqualTo(max) ||
              this.$t('form.errorAmountOutofRange', {
                range: this.minMaxRangeText,
              })
          }
          ,
        },
        bankCardNo: {
          required: value => !!value || this.$t('form.missCardNo'),
        },
        pwd: {
          required: val => !!val || this.$t('form.missPassword'),
        },
      }
    },

    /**
     * 取得主色色碼
     * @date 2021-09-06
     * @returns {string} 主色色碼
     */
    primaryColor() {
      return this.$vuetify.theme.themes[this.theme].primary
    },

    /**
     * 點擊時，btn 主色外框
     * @date 2021-09-06
     * @returns {string} border style (主色外框)
     */
    activePaymentItem() {
      return {
        border: `1px solid ${this.primaryColor} !important`,
      }
    },
  },
  watch: {
    // 金額僅能輸入數字和小數點最多兩位
    'form.amount'(newVal) {
      // 只有當 newVal 有值，並且不符合規則時才進行格式化
      if (newVal && !/^(\d+(\.\d{0,2})?)?$/.test(newVal)) {
        // console.log('Before format:', newVal)

        const formattedAmount = newVal
          .replace(/[^0-9.]/g, '') // 移除非數字和小數點的字符
          .replace(/(\..*)\./g, '$1') // 保證小數點只出現一次
          .replace(/^(\d+\.\d{2}).*/g, '$1') // 小數點後最多保留兩位

        this.$nextTick(() => {
          this.form.amount = formattedAmount
        })
        // console.log('After format:', formattedAmount)
      }
    },
  },
  async mounted() {
    this.device = deviceCheck()

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch).forEach(index => {
      this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
    })

    await this.set_toolbar_title(this.$t('global.deposit'))

    this.getUserPaymentListNDepositAmountRange()
    this.getUserBaseInfo()// 取得使用著資訊
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_user_payment_list',
      'user_deposit',
      'show_alert',
      'get_user_payment_pipeline',
      'get_user_base_info',
    ]),
    convertCurrency(target) {
      // 將表單金額依照傳入參數target(USDT/CUR),return對應換算金額(無條件捨去至小數點第二位)
      const regex = /^\d+(\.\d{1,2})?$/ // 僅接受數字和小數點
      const amount = this.form.amount
      const exchangeRate = this.usdtList[this.activeUsdtIndex]?.exchange_rate

      if (!amount || !regex.test(amount) || !exchangeRate) return '-'

      const result = target === 'USDT'
        ? new Decimal(amount).div(exchangeRate) // 法幣轉 USDT
        : new Decimal(amount).mul(exchangeRate) // USDT 轉法幣

      return this.amountRoundDown(result)
    },
    getMinMaxData(min, max) {
      // 依照付款方式和幣種單位選擇,取得對應可輸入最大&最小金額值
      const exchangeRate = this.usdtList[this.activeUsdtIndex]?.exchange_rate

      const convertToCur = (amount) => {
        if (!exchangeRate) return '-'
        return this.amountRoundDown(new Decimal(amount).mul(exchangeRate))
      }

      // 若付款方式選 非虛擬貨幣 或 虛擬貨幣且是USDT幣種單位,直接返回API最大最小值資料
      if (this.activePayType !== 'USDT' || (this.activePayType === 'USDT' && this.selectedUnit === 'USDT')) return { min, max }

      // 返回 轉成站台法幣的最大最小值資料
      return {
        min: convertToCur(min),
        max: convertToCur(max),
      }
    },
    updateUnit(val) {
      // console.log(val)
      this.selectedUnit = val
      if (this.form.amount) this.$refs.form.validate()
    },
    amountRoundDown(amt) {
      return amountRoundDown(amt)
    },

    getUserBaseInfoSuccess(data) {
      this.completed_baseinfo = data.completed_baseinfo
      // 判斷是否要顯示 跳轉完善資料頁 的彈窗
      if (this.completed_baseinfo !== 'Y' && this.isCompletedBaseinfoBeforeDeposit) {
        this.dialog.status = true
        this.dialog.type = 'baseInfo'
      }
    },

    /**
     * 取得使用者資訊
     */
    async getUserBaseInfo() {
      const userBaseInfo = await this.get_user_base_info()
      resStatus(userBaseInfo, this.getUserBaseInfoSuccess)
    },
    /**
     * 新增金額快速錢
     * @date 2021-04-06
     * @param {number} fastPlusAmount 快速新增金額 100 / 500 / 1000
     */
    plusAmount(fastPlusAmount) {
      this.form.amount = +this.form.amount + fastPlusAmount
    },

    /**
     * 取得使用者付款方式列表 (成功取得)
     * 預設付款方式 (若有為第一個 / 若無為銀行卡轉卡)
     * @date 2021-04-06
     * @param {object}} data 付款列表資料
     */
    getUserPaymentListNDepositAmountRangeSuccess({ payments, bankcard, usdt }) {
      this.userPaymentList = [...payments]
      this.bankCard = [...bankcard]
      this.usdtList = [...usdt]
      if (this.userPaymentList.length > 7) this.paymenetListAreaHidden = true
    },

    /**
     * 取得使用者付款方式列表(含各付款方式 金額範圍)
     * @date 2021-04-06
     */
    async getUserPaymentListNDepositAmountRange() {
      const res = await this.get_user_payment_list({ device: this.device })
      resStatus(res, this.getUserPaymentListNDepositAmountRangeSuccess)
    },
    /**
     * 選擇 「支付通道」 功能
     * @date 2022-01-18
     * @param {any} channel
     * @returns {any}
     */
    setPipelineItem(channel, index) {
      // console.log(channel)
      this.activePipelineItem = channel.code + channel.paytype_no + index
      this.activePipeline = channel
      this.activeCurrencySymbol = channel.currency_symbol
      this.form.paytype_no = channel.paytype_no
      this.activePaymentType = channel.type
      this.form.paytype = channel.code

      this.rangeOrOption(channel.type, this.activePipeline)
    },
    /**
     * 選擇協議
     * @date 2022-01-18
     * @param {any} channel
     * @returns {any}
     */
    setProtocolItem(protocol, index) {
      this.activeProtocolIndex = index
      this.form.protocol = protocol
    },

    /**
     * 處理支付型態 (range || option)
     * range => 設置 「存款金額」 範圍
     * option => 設置 「直接點選金額」 不輸入
     * @date 2022-01-18
     * @param {string} type 支付類別 range || option
     * @param {any} activeItem={} 點擊的支付方式 (付款方式 || 管道)
     */
    rangeOrOption(type, activeItem = {}) {
      // 設置單筆儲金範圍
      if (type === 'range') {
        this.resetFastAmountBtn()
        this.setPaymentAmountRange(activeItem)
      }

      // 點選金額快捷
      if (type === 'option') {
        const numAry = []
        const numStr = activeItem.options.split(',')
        this.resetAmountRang()
        numStr.forEach(item => numAry.push(Number(item)))
        this.fastAmountBtnForOption = numAry
      }
    },

    /**
     * (成功) 取得 [支付通道]
     * @date 2022-01-18
     * @param {object} {channels} 通道資料
     */
    getUserPaymentPipelineSuccess({ channels }) {
      this.pipelineList = []
      this.pipelineList = channels
    },

    getUserPaymentPipelineFail(errors) {
      this.show_alert({
        icon: 'fail',
        text: errors,
      })
    },

    /**
     * 取得 [支付通道]
     * @date 2022-01-18
     * @param {string} payType 付款方式 代碼
     * @param {string} device 裝置名稱 all || ios || android
     */
    async getUserPaymentPipeline(payType, device, currency, paymentPlatform) {
      const res = await this.get_user_payment_pipeline({
        payType,
        device,
        currency,
        payment_platform: paymentPlatform,
      })
      resStatus(
        res,
        this.getUserPaymentPipelineSuccess,
        this.getUserPaymentPipelineFail,
      )
    },

    /**
     * 設置存款金額範圍 (依目前選取的付款方式)
     * @date 2021-04-06
     * @param {object} paymentData 依目前選取的付款方式資料
     */
    setPaymentAmountRange(paymentData) {
      const { options } = paymentData
      const amountRange = options.split(',')

      this.resetAmountRang()
      this.amount.min_amount = amountRange[0]
      this.amount.max_amount = amountRange[1]
    },

    // 重置金額範圍
    resetAmountRang() {
      this.amount.min_amount = 0
      this.amount.max_amount = 9999999
    },

    // 重置快捷金額
    resetFastAmountBtn() {
      this.fastAmountBtn = [100, 500, 1000]
    },

    /**
     * 設置選取的付款方式 (active)
     * @date 2021-04-06
     * @param {string}} type 付款方式代碼
     */
    setPayType(type, currencySymbol, currency, paymentPlatform, selectItem) {
      this.getUserBaseInfo()
      // if (this.activePayType === type) return false
      // console.log(type)
      type === 'USDT' ? this.selectedUnit = 'USDT' : this.selectedUnit = this.currencySymbol_
      this.activePayType = type
      this.activeCurrencySymbol = currencySymbol
      this.activePipelineItem = null
      this.resetForm()
      this.resetAmountRang()
      this.resetFastAmountBtn()
      this.activePaymentType = null
      this.activeUsdtIndex = null
      this.activePipeline = null
      this.form.paytype = null
      this.form.paytype_no = null
      this.form.protocol = null
      this.pipelineList = []
      this.activeCurrency = currency
      this.usdtListShow = false
      this.protocolClickStatus = false
      this.activeProtocolIndex = null
      type === 'USDT' ? this.form.paytype = selectItem.code : this.form.paytype = type

      if (this.showPaymentPipeline && type !== 'BANK' && type !== 'USDT') {
        this.getUserPaymentPipeline(this.form.paytype, this.device, currency, paymentPlatform)
      }

      let activePaymentData = []

      // 儲存目前點擊的支付方式資料
      if (type === 'BANK') {
        activePaymentData = [...this.bankCard][0]
      } else if (type === 'USDT' && selectItem) {
        // console.log(selectItem)
        activePaymentData = { ...selectItem }
        this.usdtListShow = true
      } else {
        activePaymentData = this.userPaymentList.filter(
          item => item.code === type,
        )[0]
      }

      // 依「支付類型」 設置存款資料 (範圍/快捷)
      this.activeUsdtIndex = activePaymentData.selIndex
      this.activePaymentType = activePaymentData.type
      this.activePaymentPlatform = activePaymentData.payment_platform
      this.rangeOrOption(activePaymentData.type, activePaymentData)
    },

    closeDialog() {
      this.dialog.status = false
      this.dialog.content = []
      this.dialog.type = ''
    },

    /**
     * 表單重置
     * @date 2021-09-06
     */
    resetForm() {
      this.$refs.form.reset()
    },

    resetPipelineData() {
      this.pipelineList = []
    },

    /**
     * 存款成功的響應處理 (type === BANK || type === USDT)
     * @date 2022-01-22
     * @param {any} {type 響應資料的類別 (BANK和USDT為顯示 存款資料 供使用者匯款)
     * @param {any} payinfo} 響應內容  (所有匯款資料，除了使用者輸入的金額)
     */
    resTypeBank({ type, payinfo }) {
      this.dialog.content = { ...payinfo }
      this.dialog.content.amount = this.form.amount
      this.dialog.type = type
    },

    /**
     * 存款成功的響應處理 (type === url)
     * @date 2022-01-22
     * @param {string} {type 響應資料的類別 (url 為顯示 url為qrCode)
     * @param {object} payinfo} 響應內容  (type: 'url',payinfo的info為 url 址)
     */
    resTypeUrl({ type, payinfo }) {
      this.dialog.content = payinfo.url
      this.dialog.type = 'url'
      setTimeout(() => {
        window.open(payinfo.url, '_blank')
      })
    },

    /**
     * 存款 (成功)
     * 1 開啟狀態彈窗
     * 2 響應內容彈窗處理 (show qrCode 或 匯款資料)
     * 3 資料重置
     * @date 2022-01-22
     * @param {object} depositData 響應的存款資料
     */
    depositSuccess(depositData) {
      this.show_alert({
        icon: 'done',
        // text: this.$t('global.deposit') + this.$t('global.success'),
      })

      const { type, payinfo } = depositData

      if (type === 'BANK' || (type === 'USDT' && payinfo && !payinfo?.url)) {
        this.resTypeBank({ type, payinfo })
        setTimeout(() => (this.dialog.status = true), 800)
      } else {
        this.resTypeUrl({ type, payinfo })
      }

      this.resetForm()
      this.resetPipelineData()
      this.usdtListShow = false
      this.activePayType = null
      this.form.paytype = null
    },

    /**
     * 存款 (成功)
     * 1 狀態彈窗
     * 2 資料重置
     * @date 2022-01-22
     */
    depositFail(error) {
      this.show_alert({
        icon: 'fail',
        // text: this.$t('global.deposit') + this.$t('global.fail'),
        delayMs: 2000,
        text: this.$t('global.deposit') + this.$t('global.fail') + ':' + error,
      })
      this.resetForm()
      this.resetPipelineData()
      this.usdtListShow = false
      this.activePayType = null
      this.form.paytype = null
    },

    /**
     * 存款
     * @date 2022-01-22
     * @param {object} depositData 存款資料 {paytype, paytype_no, amount}
     * paytype_no 管道代碼
     */
    async deposit(depositData) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.deposit.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.deposit.setStatus(false)

      const depositDataCopy = { ...depositData }

      // 因原本api都是用大寫USDT當請求參數,但僅有存款是改小寫usdt,所以直接在送出時改寫資料改動最小
      if (depositDataCopy.paytype === 'USDT') depositDataCopy.paytype = 'usdt'

      // 當幣種單位選擇USDT時,則需要將表單U金額改成法幣金額送出
      if (this.activePayType === 'USDT' && this.selectedUnit === 'USDT') depositDataCopy.amount = this.estimatedCUR

      // 若支付方式不為通道，刪除 paytype_no 屬性
      if (!this.activePipeline) delete depositDataCopy.paytype_no
      depositDataCopy.payment_platform = this.activePayType === 'BANK' ? 'private' : this.activePaymentPlatform
      const res = await this.user_deposit(depositDataCopy)
      resStatus(res, this.depositSuccess, this.depositFail)
    },

    /**
     * 復制文字功能
     * @date 2021-04-07
     * @param {string} id 需要復製的文字 id
     */
    copyText(id) {
      try {
        copy(id)
        this.show_alert({
          icon: 'done',
          text: this.$t('global.copy') + this.$t('global.success'),
        })
      } catch {
        this.show_alert({
          icon: 'fail',
          text: this.$t('global.copy') + this.$t('global.fail'),
        })
      }
    },

    /**
     * 支付類型 (option) 存款金額設置
     * @date 2021-09-06
     * @param {any} amount
     */
    typeOptionSetAmount(amount) {
      this.form.amount = amount
    },
  },
}
