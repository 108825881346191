<template>
  <!-- 取款 -->
  <div class="pt-56">
    <ToolbarTemplate1>
      <div slot="btn">
        <v-btn
          icon
          @click="getAllWallet"
        >
          <Icon
            data="@icon/fetch.svg"
            width="24px"
            height="24px"
            class="title--text"
          />
        </v-btn>
      </div>
    </ToolbarTemplate1>
    <v-container>
      <v-row>
        <v-col cols="12">
          <Wallet />
        </v-col>
        <v-col
          cols="12"
          class="py-0"
        >
          <v-divider class="card-1" />
        </v-col>
      </v-row>

      <!-- 取款至銀行卡，顯示條件：後台設置[取款方式]設置 銀行卡 -->
      <v-row>
        <v-col
          cols="12"
          class="withdrawalToBank"
        >
          <div class="withdrawalToBank__title title--text font-weight-medium">
            {{ $t('myCenter.withdrawalMethods') }}
          </div>
          <v-container class="mt-4">
            <v-row>
              <v-form
                ref="form"
                v-model="valid"
              >
                <!-- 使用者銀行卡列表模式 -->
                <template v-if="!withdrawalIsFlatten">
                  <v-col
                    v-for="card in userBankCardList"
                    :key="card._id"
                    cols="12"
                    class="py-1 px-0"
                  >
                    <v-btn
                      block
                      :text="form.bank_no === card.bank_no ? false : true"
                      :outlined="form.bank_no === card.bank_no ? true : false"
                      :color="form.bank_no === card.bank_no ? 'primary' : ''"
                      class="p-relative"
                      :class="[
                        form.bank_no === card.bank_no
                          ? 'primary--text'
                          : 'subtitle--text card1',
                      ]"
                      @click="setUserBankCardNumber(card)"
                    >
                      <div class="text-center d-flex">
                        <v-img
                          :src="baseImgUrl + card.image"
                          class="mr-2"
                          height="18"
                          width="18"
                          contain
                        />
                        <div class="d-flex align-center">
                          {{ getCardTitle(card) }}
                        </div>
                      </div>
                      <div class="d-flex align-center bank_no">
                        {{ card.slug || card.bank_no.substr(card.bank_no.length - 4, 4) }}
                      </div>
                    </v-btn>
                  </v-col>

                  <!-- 新增卡片 -->
                  <v-col
                    cols="12"
                    class="py-1 px-0"
                  >
                    <v-btn
                      to="/user/card"
                      block
                      text
                      class="card1"
                    >
                      <div class="text-center d-flex">
                        <div class="d-flex align-center subtitle--text">
                          <v-icon
                            color="secondary"
                            class="mr-2"
                          >
                            mdi-plus-circle
                          </v-icon>
                          {{ $t('flashMessage.addCards') }}
                        </div>
                      </div>
                    </v-btn>
                  </v-col>
                </template>
                <!-- 直接填寫模式 -->
                <template v-else>
                  <v-col
                    cols="12"
                  >
                    <!-- 一般提幣選項 -->
                    <v-btn
                      class="cur-btn"
                      size="small"
                      color="card2"
                      :style="form.bank_id!=='usdt' ? activeTypeItem : ''"
                      @click="setBankId()"
                    >
                      <span
                        class="caption mt-1 font-weight-medium rounded-xl subtitle--text"
                        :class="[
                          form.bank_id!=='usdt'
                            ? 'primary--text'
                            : 'subtitle--text',
                        ]"
                      >
                        {{ $t("myCenter.normalCurrency") }}
                      </span>
                    </v-btn>
                    <!-- usdt提幣選項 -->
                    <v-btn
                      v-if="cryptoList.length>0"
                      class="cur-btn"
                      color="card2"
                      :style="form.bank_id==='usdt' ? activeTypeItem : ''"
                      @click="setBankId('usdt')"
                    >
                      <span
                        class="caption mt-1 font-weight-medium rounded-xl subtitle--text"
                        :class="[
                          form.bank_id==='usdt'
                            ? 'primary--text'
                            : 'subtitle--text',
                        ]"
                      >
                        {{ $t("myCenter.usdtCurrency") }}
                      </span>
                    </v-btn>
                  </v-col>

                  <!-- 開戶姓名 -->
                  <v-col
                    v-if="form.bank_id!=='usdt'"
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <span class="title--text mb-1 subtitle-2">
                      {{ $t('myCenter.bankAccountName') }}
                    </span>
                    <v-text-field
                      v-model="form.defray_name"
                      class="mt-2"
                      min-height="36"
                      dense
                      outlined
                      required
                      :rules="[
                        rules.required.defrayName
                      ]"
                    />
                  </v-col>

                  <!-- 開戶銀行 -->
                  <v-col
                    v-if="form.bank_id!=='usdt'"
                    cols="12"
                    class="py-0"
                  >
                    <span class="title--text mb-1 subtitle-2">
                      {{ $t('deposit.bank') }}
                    </span>
                    <v-text-field
                      v-model="dispalyInput.bankName"
                      class="mt-2"
                      max-height="36"
                      readonly
                      dense
                      outlined
                      required
                      :rules="[rules.required.bankId]"
                      :placeholder="$t('form.missBank')"
                      @click="bankDialog.status = true"
                    >
                      <div
                        v-if="activeBank"
                        slot="prepend-inner"
                        class="bank__inner d-flex align-center p-relative"
                      />
                      <div slot="append">
                        <v-icon size="23">
                          mdi-chevron-right
                        </v-icon>
                      </div>
                    </v-text-field>
                  </v-col>

                  <!-- 銀行分行(印度盧比顯示) -->
                  <v-col
                    v-if="form.bank_id!=='usdt' && currencySymbol==='INR'"
                    cols="12"
                    class="py-0"
                  >
                    <span class="title--text mb-1 subtitle-2">
                      {{ $t('myCenter.bankNameIFSC') }}
                    </span>
                    <v-text-field
                      v-model="form.ifsc_code"
                      class="mt-2"
                      min-height="36"
                      dense
                      outlined
                      required
                      :placeholder="$t('form.missBankNameIFSC')"
                      :rules="[
                        rules.required.bankNameIFSC,
                        rules.ifsc.bankName,
                      ]"
                    />
                  </v-col>

                  <!-- 別名 -->
                  <v-col
                    v-if="form.bank_id==='usdt'"
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <span class="title--text mb-1 subtitle-2">
                      {{ $t('myCenter.alias') }}
                    </span>
                    <v-text-field
                      v-model="form.slug"
                      class="mt-2"
                      min-height="36"
                      dense
                      outlined
                      required
                      :placeholder="$t('form.missSlug')"
                      :rules="[
                        rules.required.slug
                      ]"
                    />
                  </v-col>

                  <!-- USDT協議列表 -->
                  <v-col
                    v-if="form.bank_id==='usdt'"
                    cols="12"
                    class="py-0 mb-14"
                  >
                    <span class="title--text mb-1 subtitle-2">
                      {{ $t("deposit.protocol") }}
                    </span><br>

                    <v-btn
                      v-for="(p,i) in protocolList"
                      :key="i"
                      class="mt-2 cur-btn"
                      size="small"
                      depressed
                      active-class="test"
                      color="card2"
                      :style="p.code === form.protocol ? activeTypeItem : ''"
                      @click="form.protocol=p.code"
                    >
                      <span
                        class="caption mt-1 font-weight-medium rounded-xl subtitle--text"
                        :class="[
                          p.code === form.protocol
                            ? 'primary--text'
                            : 'subtitle--text',
                        ]"
                      >
                        {{ p.name }}
                      </span>
                    </v-btn>
                  </v-col>

                  <!-- 銀行卡號/usdt錢包地址 -->
                  <v-col
                    cols="12"
                    class="py-0"
                  >
                    <span class="title--text mb-1 subtitle-2">
                      {{ form.bank_id==='usdt'?$t("myCenter.cryptoAdress"):$t('myCenter.bankCardNo') }}
                    </span>
                    <v-text-field
                      v-model.trim="form.bank_no"
                      class="mt-2"
                      max-height="36"
                      dense
                      :placeholder="form.bank_id==='usdt'?$t('form.missWalletAdress'):$t('form.missCardNo')"
                      single-line
                      outlined
                      inputmode="numeric"
                      required
                      clear-icon="mdi-close-circle"
                      clearable
                      :rules="[
                        rules.required.bankNo,
                        rules.bankNo.minLength,
                        rules.bankNo.maxLength,
                        rules.bankNo.usdtAdress,
                      ]"
                      @keyup="bankIdFormat"
                    >
                      <div slot="clear-icon">
                        <v-icon>mdi-close-circle</v-icon>
                      </div>
                    </v-text-field>
                    <span
                      v-if="form.bank_id==='usdt'"
                      class="comment--text caption"
                    >{{ $t('myCenter.usdtAlert') }}</span>
                  </v-col>

                  <!-- 开户省市 (僅有 cn 需要選擇 「開戶省分」) -->
                  <v-col
                    v-if="nation === 'china' && form.bank_id!=='usdt'"
                    cols="12"
                    class="py-0"
                  >
                    <span class="title--text mb-1 subtitle-2">
                      {{ $t('myCenter.provinceCity') }}
                    </span>
                    <v-text-field
                      class="mt-2"
                      max-height="36"
                      dense
                      outlined
                      required
                      readonly
                      :placeholder="$t('form.missProvince')"
                      :value="form.bank_province + '  ' + form.bank_city"
                      :rules="[rules.required.provinceNcity]"
                      @click="showSheet"
                    >
                      <div slot="append">
                        <v-icon size="23">
                          mdi-chevron-right
                        </v-icon>
                      </div>
                    </v-text-field>
                  </v-col>
                </template>

                <!-- 分隔線 -->
                <v-col
                  cols="12"
                  class="pt-5 pb-0 px-0"
                >
                  <v-divider class="card-1" />
                </v-col>

                <!-- 取款金額 -->
                <v-col
                  cols="12"
                  class="px-0"
                >
                  <div
                    class="withdrawalToBank__title title--text font-weight-medium"
                  >
                    {{ $t('global.withdraw') + $t('global.amount') }}
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                        class="px-0 pb-0"
                      >
                        <v-text-field
                          v-model="form.bank_no"
                          class="d-none"
                          :rules="[rules.amount.required]"
                        />
                        <!-- 取款金額 -->
                        <v-text-field
                          v-model.trim="form.amount"
                          onkeyup="this.value=this.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                          max-height="36"
                          dense
                          :placeholder="$t('form.missAmount')"
                          single-line
                          outlined
                          required
                          clear-icon="mdi-close-circle"
                          clearable
                          type="number"
                          inputmode="decimal"
                          :rules="[
                            rules.amount.required,
                            rules.amount.min,
                            rules.amount.overBlance,
                            rules.amount.overCount,
                            rules.amount.max,
                          ]"
                        >
                          <div slot="clear-icon">
                            <v-icon>mdi-close-circle</v-icon>
                          </div>
                        </v-text-field>
                      </v-col>

                      <!-- 取款範圍 -->
                      <v-col
                        cols="6"
                        class="px-0 pb-0 caption comment--text"
                      >
                        <div class="pl-4 d-flex flex-column justify-start">
                          <span>
                            {{ $t('myCenter.singleWithdrawRange') }}
                          </span>
                          <span>
                            {{
                              currencySymbol_ +
                                amount.min_amount +
                                '~' +
                                currencySymbol_ +
                                amount.max_amount
                            }}
                          </span>
                        </div>
                      </v-col>

                      <!-- 今日可用額度 -->
                      <v-col
                        cols="12"
                        class="comment--text caption pt-0 px-0"
                      >
                        <!-- 預計到帳＆參考匯率 -->
                        <div v-if="form.bank_id === 'usdt'">
                          <span class="mr-2">{{ $t('form.CNY2USDT') }}：</span>
                          <span class="primary--text mr-5">
                            {{ CNY2USDT }}
                          </span>
                          <span><Icon
                            data="@icon/info.svg"
                            width="12"
                            height="12"
                            class="mgb"
                            style="margin-bottom: 3px"
                          /> {{ $t("deposit.rate") }}</span><span class="primary--text">
                            {{ `1 USDT ≈ ${usdtWithdrawalRate} ${currencySymbol}` }}
                          </span>
                        </div>
                        <!-- 已達投注額 -->
                        <div class="mt-1">
                          <span class="mr-2">{{ $t('form.reachBetAmount') }}</span>
                          <span class="primary--text">
                            {{ currencySymbol_ + amount.valid_bet_total }}
                          </span>
                        </div>
                        <!-- 未達投注額 -->
                        <div class="mt-1">
                          <span class="mr-2">{{ $t('form.notReachBetAmount') }}</span>
                          <span class="primary--text">
                            {{ currencySymbol_ + unReachBetAmount }}
                          </span>
                        </div>
                        <!-- 可以次數 -->
                        <div class="mt-1">
                          <span class="mr-2">{{ $t('myCenter.withdrawAvailableTime') }}</span>
                          <span class="primary--text">
                            {{ amount.left_count }}
                          </span>
                        </div>

                        <!-- 可用額度 -->
                        <div class="mt-1">
                          <span class="mr-2">{{ $t('myCenter.withdrawAvailableAmount') }}</span>
                          <span class="primary--text">
                            {{ currencySymbol_ + amount.left_amount }}
                          </span>
                        </div>
                      </v-col>

                      <!-- 取款按鈕 -->
                      <v-col class="pa-0 pt-5">
                        <v-btn
                          block
                          color="primary"
                          height="44"
                          :disabled="valid ? false : true"
                          @click="setDialogState(true, 'withdrawals')"
                        >
                          <span class="subtitle-1 btnText--text">
                            {{ $t('global.withdraw') }}
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <!-- 後台設置[取款方式]是直接取得，顯示添加銀行卡元件 -->
      <!-- <v-row v-if="withdrawalIsFlatten">
        <component :is="layoutTemplate" />
      </v-row> -->
    </v-container>

    <!-- Dialog (完善基本資料) -->
    <!-- 後台設定[提款]為直接攤平，則不顯示 (withdrawalIsFlatten = false) -->
    <Dialog
      :dialog="dialog.status && dialog.type === 'baseInfoHaveToCheck' && !withdrawalIsFlatten"
      :width="280"
      :height="150"
    >
      <!-- 如果沒有完善基本資料 -->
      <template v-if="dialog.type === 'baseInfoHaveToCheck'">
        <div
          slot="text"
          class="text px-6"
        >
          {{ $t('myCenter.firstDeposit') }}
        </div>
        <v-btn
          slot="btn"
          text
          class="subtitle"
          @click="dialog.status = false"
        >
          {{ $t('global.cancel') }}
        </v-btn>
        <v-btn
          slot="btn"
          to="/user/personal"
          text
          color="primary"
        >
          {{ $t('global.confirmation') }}
        </v-btn>
      </template>
    </Dialog>

    <!-- Dialog (取款) -->
    <Dialog
      :dialog="dialog.status && dialog.type === 'withdrawals'"
      :width="280"
      :height="202"
    >
      <!-- 如果要提款 -->
      <template v-if="dialog.type === 'withdrawals'">
        <div slot="title">
          <span class="subtitle-1 title--text">
            {{ $t('form.misswithdrawPassword') }}
          </span>
        </div>

        <div
          slot="text"
          class="px-6"
        >
          <span class="title--text caption">
            {{ $t('myCenter.withdrawPassword') }}
          </span>
          <v-form
            ref="pwdForm"
            v-model="pwdValid"
            lazy-validation
          >
            <!-- 輸入 提款密碼 -->
            <v-text-field
              v-model="form.withdrawal_password"
              type="password"
              class="mt-2"
              autofocus
              max-height="36"
              dense
              :placeholder="$t('form.missPassword')"
              single-line
              outlined
              required
              clear-icon="mdi-close-circle"
              clearable
              :rules="[rules.pwd.required]"
            />
          </v-form>
        </div>

        <div slot="btn">
          <v-btn
            color="grey"
            text
            @click="setDialogState()"
          >
            {{ $t('global.cancel') }}
          </v-btn>

          <!-- 使用者取款 submit -->
          <v-btn
            :disabled="!pwdValid ? true : false"
            color="primary"
            text
            @click="userWithdrawals(form)"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </div>
      </template>
    </Dialog>

    <!-- 選擇銀行 Dailog -->
    <v-dialog
      v-model="bankDialog.status"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="bg">
        <div class="p-fixed w-100 bg">
          <v-toolbar
            class="bg"
          >
            <v-btn
              icon
              @click="closeSearchBankList"
            >
              <Icon
                data="@icon/toolbar/arrow_left.svg"
                width="16px"
                height="16px"
                class="icon--text"
              />
            </v-btn>
            <v-toolbar-title>
              {{ $t('form.missBank') }}
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>

          <!-- 搜尋銀行 -->
          <v-list
            three-line
            subheader
            class="bg"
          >
            <div class="search-area">
              <v-text-field
                id="searchInput"
                v-model.trim="bankDialog.searchKeyword"
                autofocus
                class="mx-4 mt-2 inputBg"
                hide-details
                :placeholder="$t('game.searchGame')"
                rounded
                autocomplete="off"
                dense
              >
                <!-- 放大鏡 -->
                <div
                  slot="prepend-inner"
                  class="mt-0"
                >
                  <v-btn
                    slot="btn"
                    icon
                  >
                    <Icon
                      data="@icon/search.svg"
                      width="24"
                      height="24"
                      class="white--text"
                    />
                  </v-btn>
                </div>
              </v-text-field>
            </div>
          </v-list>
        </div>

        <!-- 銀行列表 -->
        <v-list
          class="bg pt-110px"
          flat
        >
          <v-list-item-group
            color="primary"
          >
            <v-list-item
              v-for="bankItem in filterBankList"
              :key="bankItem._id"
              @click="setBankId(bankItem.code)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="bankItem.name" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- 開戶省市 Sheet -->
    <v-bottom-sheet v-model="sheet.status">
      <v-sheet class="text-center">
        <v-list height="318px">
          <!-- province -->
          <template v-if="!form.bank_province">
            <v-subheader class="px-6">
              <span class="text-h6 title--text">
                {{ $t('myCenter.selectProvince') }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(item, province) in sheet.cities"
                :key="province"
                class="text-left px-6"
                @click="setProvince(province)"
              >
                <v-list-item-title>{{ province }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>

          <!-- cities -->
          <template v-else>
            <v-subheader class="px-6">
              <v-icon
                class="mr-6"
                color="primary"
                @click="resetProvince"
              >
                mdi-chevron-left
              </v-icon>
              <span class="text-h6 title--text">
                {{ form.bank_province }}
              </span>
              <v-spacer />
              <v-icon
                color="title"
                @click="closeSheet"
              >
                mdi-close
              </v-icon>
            </v-subheader>
            <div class="overflow-auto h-318 pb-56 list">
              <v-list-item
                v-for="(city, province) in sheet.cities[form.bank_province]"
                :key="province"
                class="text-left px-6"
                @click="setCities(city)"
              >
                <v-list-item-title>{{ city }}</v-list-item-title>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import indexMixin from '../mixins/index'
import { curryTemplate } from '@/utils/template'
// component
import template1 from '../../card/template/AddCardTemplate1.vue'
import template3 from '../../card/template/AddCardTemplate3.vue'
import template4 from '../../card/template/AddCardTemplate4.vue'

export default {
  name: 'WithdrawalTemplate1',
  components: { },
  mixins: [indexMixin],
  computed: {
    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
      }
      return curryTemplate(this.layout, templateObj)
    },
  },
}
</script>

<style lang="scss" scoped>
.bank_no {
	position: absolute;
	right: 0;
}

.bank {
	&__icon {
		display: inline-block;
		width: 18px;
		height: 18px;
	}
}

.h-318 {
	height: 318px;
}

.p-fixed {
	top: 0;
	z-index: 2;
}

.pt-110px {
	padding-top: 110px;
}
.cur-btn{
  max-width: 130px;
}
.cur-btn span{
  padding: 0 24px;
}
.cur-btn + .cur-btn{
margin-left: 10px;
}
.mb-14{
  margin-bottom: 14px!important;
}
.protocol-btn+.protocol-btn{
  margin-left: 10px;
}
</style>
